#body {
    text-align: center;
}

body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    margin-bottom: 200px;
}

#footer {
    bottom: 0;
    width: 100%;
    position: fixed;
}

#brand {
    padding-left: 10px;
}

.btn {
    width: 13rem;
}

.buttons {
    color: white;
    font-size: 12px;
}

.collection-item {
    padding: 0.8rem;
}

.content-list {
    color: grey;
    margin: auto;
    text-align: left;
    width: 80%;
    overflow-wrap: break-word;

}

.file-upload {
    margin-bottom: 2rem;
}

.form {
    margin: auto;
    width: 80%;
    padding: 1rem
}

#brand-logo {
    position: relative
}

#bold {
    font-weight: bold;
    color: black
}

#csp-button {
    margin: 1rem
}

#choose-file {
    margin-left: 8rem
}

#provider-list {
    justify-content: center;
    padding: 1rem;
    margin: 1rem;
}

#s3Button {
    margin-top: 1rem;
}

#send-grab-button {
    margin-right: 5px;
}

#status-box {
    outline: 1px solid gray;
    margin: auto;
    margin-top: 2rem;
    color: orange;
    font-size: 20px;
    width: 50%;
    justify-self: center;
}

.user-info {
    margin-bottom: 1em;
}

.logout-button {
    margin-top: 1em;
}

.btn-block-fw {
    width: 100%;
}

#app-content-login {
    background: whitesmoke;
    height: 100vh;
    width: 100vw;
    padding-top: 10vh;
}

#c-org-info {
    font-size: 0.5em;
    font-weight: bold;
}

.nav-button {
    margin-right: 1em;
}

.input-field.col {
    width: 100%;
}

.card {
    padding: 1em;
    margin: 1em;
}

.no-pad {
    padding: 0;
}
.no-margin {
    margin: 0;
}

nav .brand-logo {
    font-size: 0.9em;
    margin-left: 10px;
}

#brand-container{
    padding-right: 20px;
    padding-left: 20px;
}
.login-button {
    overflow: visible;
    line-break: strict;
    max-lines: 1;
    line-height: normal;
}

.accordion-item {
    list-style: none;
}

.accordion-toggle {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #ddd;
    padding: 1em;
}

.accordion-toggle:hover {
    background-color: #eee;
}

.accordion-toggle h3 {
    margin: 0;
}

.accordion-content {
    border: 1px solid #ddd;
    padding: 1em;
}

input[type="checkbox"]:not(:checked), input[type="checkbox"]:checked {
    position: relative;
    opacity: 1;
    margin: 10px;
}